// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any
const { version: appVersion } = require('../../package.json')

export const environment2 = {
  production: false,

};
//    domain: 'http://192.168.7.45:8100',
//    api: 'wss://qbo.dev.facepay.io/api',
//    api: 'wss://api.facepay.io:3000'


export const environment = {
    production: false,
    version: appVersion

};
export const FacepayX= {
    quickbooks: 'https://qbo.facepay.io',
    amazon: 'https://amazon.facepay.io',



    domain: 'https://login.facepay.io',
    api_direct: 'wss://api.facepay.io',


    api: 'wss://proxy.facepay.io/api',
    twilio: 'https://proxy.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.facepay.io',

    dwolla: 'https://proxy.facepay.io/dwolla',
    qr: 'https://dwolla.facepay.io',

    plaid: 'https://proxy.facepay.io/plaid',
    services: 'https://proxy.facepay.io/services',
    sift: 'https://proxy.facepay.io/services',
    link: 'https://proxy.facepay.io/link',


    plaid_env: 'production',//'development',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '1963481312',



    guest : {
        limit: 100
    }


};

export const Auth0X = {
    "clientId": "jp2UReuEO3d3irN3CCTYMAyzFhWgnu82",
    "domain": "facepay.auth0.com",
    "audience": "https://platform.facepay.io",
    "callback_uri": `${window.location.origin}/auth0-callback`,
    "redirect_uri_business": `${window.location.origin}/members/menu//guests-init-two-factor`,
    "redirect_uri_setup": `${window.location.origin}/public/desktop/setup-page`,
    "redirect_uri_claim": `${window.location.origin}/claim-complete/:uuid`,
    "redirect_uri_affiliatepay": `${window.location.origin}/affiliate/pay`,

    "redirect_uri_activate": `${window.location.origin}/activate/:bid/code`,
    "redirect_uri_admin": `${window.location.origin}/test`,
    "redirect_uri_guestpurchasetwofactor": `guest/business/:bid/guests/:gid/purchases/:pid/two-factor`,
    "redirect_uri_guestpurchasetwofactorwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/purchases/:pid/two-factor?isPicture=:isPicture&cell=:cell&amount=:amount&date=:date&bname=:bname&bgravatar=:bgravatar`,

    "redirect_uri_guestwelcomechooser": `guest/business/:bid/guests/:gid/welcome/chooser`,
    "redirect_uri_guestwelcomesuccesswechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/success`,

    "redirect_uri_guestwelcomechooserwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/chooser?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar`,

    "redirect_uri_guestwelcomesignup": `guest/business/:bid/guests/:gid/welcome/signup`,
    "redirect_uri_guestwelcometwofactor": `guest/business/:bid/guests/:gid/welcome/two-factor`,
    "redirect_uri_guestwelcometwofactorwithparams": `guest/business/:bid/guests/:gid/welcome/two-factor?uuid=:uuid&name=:name&gravatar=:gravatar`,
    "redirect_uri_guestwelcomeplaid": `guest/business/:bid/guests/:gid/welcome/plaid`,
    "redirect_uri_guestwelcomesuccess": `guest/business/:bid/guests/:gid/welcome/success`,
    "redirect_uri_guestpurchase": `guest/business/:bid/guests/:gid/purchases/:pid`,

}

export const FacepayABC = {
    quickbooks: 'https://qbo.dev.facepay.io',
    amazon: 'https://amazon.dev.facepay.io',


    domain: 'https://login.dev.facepay.io',
    api_direct: 'wss://api.dev.facepay.io',


    api: 'wss://proxy.myfacepay.io/api',
    twilio: 'https://proxy.myfacepay.io/twilio',
    dwolla: 'https://proxy.myfacepay.io/dwolla',
    qr: 'https://dwolla.dev.facepay.io',
    plaid: 'https://proxy.myfacepay.io/plaid',
    services: 'https://proxy.myfacepay.io/services',
    sift: 'https://proxy.myfacepay.io/services',
    link: 'https://proxy.myfacepay.io/link',

    plaid_env: 'sandbox',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '740232d501',

    guest : {
        limit: 100
    }


};

export const Facepay= {
    quickbooks: 'https://qbo.dev.facepay.io',
    amazon: 'https://amazon.dev.facepay.io',


    domain: 'https://login.dev.facepay.io',
    api_direct: 'wss://api.dev.facepay.io',


    api: 'wss://proxy.dev.facepay.io/api',
    twilio: 'https://proxy.dev.facepay.io/twilio',
    dwollaTokens: 'https://dwolla.dev.facepay.io',
    dwolla: 'https://proxy.dev.facepay.io/dwolla',
    qr: 'https://dwolla.dev.facepay.io',
    plaid: 'https://proxy.dev.facepay.io/plaid',
    services: 'https://proxy.dev.facepay.io/services',
    sift: 'https://proxy.dev.facepay.io/services',
    link: 'https://proxy.dev.facepay.io/link',

    plaid_env: 'sandbox',
    plaid_key: '312ca18ffbec548c2fa4fd39518c29',


    plaid_salesforce_env: 'sandbox',
    plaid_salesforce_key: '312ca18ffbec548c2fa4fd39518c29',

    siftBeacon: '740232d501',

    guest : {
        limit: 100
    }


};

export const Auth0 = {

    // "clientId": "7klMP5ouh26MmHMKWLyb6yenSxWmmElD",
    // "domain": "facepay-customer.us.auth0.com",
    // "audience": "https://platform.dev.myfacepay.io",

    "clientId": "KIT3bT9pnl4d9GNPRnk2uuuFEGwZEWIU",
    "domain": "facepay.auth0.com",
    "audience": "https://platform.dev.facepay.io",
    "callback_uri": `${window.location.origin}/auth0-callback`,
    "redirect_uri_business": `${window.location.origin}/members/menu/guests-init-two-factor`,
    "redirect_uri_setup": `${window.location.origin}/public/desktop/setup-page`,
    "redirect_uri_admin": `${window.location.origin}/test`,
    "redirect_uri_activate": `${window.location.origin}/activate/:bid/code`,
    "redirect_uri_claim": `${window.location.origin}/claim-complete/:uuid`,
    "redirect_uri_affiliatepay": `${window.location.origin}/affiliate/pay`,

    "redirect_uri_guestpurchasetwofactor": `guest/business/:bid/guests/:gid/purchases/:pid/two-factor`,
    "redirect_uri_guestpurchasetwofactorwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/purchases/:pid/two-factor?isPicture=:isPicture&cell=:cell&amount=:amount&date=:date&bname=:bname&bgravatar=:bgravatar`,
    "redirect_uri_guestwelcomechooser": `guest/business/:bid/guests/:gid/welcome/chooser`,
    "redirect_uri_guestwelcomechooserwechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/chooser?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar`,
    "redirect_uri_guestwelcomesignup": `guest/business/:bid/guests/:gid/welcome/signup`,
    "redirect_uri_guestwelcometwofactor": `guest/business/:bid/guests/:gid/welcome/two-factor`,
    "redirect_uri_guestwelcomehome": `guest/business/:bid/guests/:gid/welcome/home?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar`,
    "redirect_uri_guestwelcometwofactorwithparams": `guest/business/:bid/guests/:gid/welcome/two-factor?uuid=:uuid&name=:name&gravatar=:gravatar`,
    "redirect_uri_guestwelcomeplaid": `guest/business/:bid/guests/:gid/welcome/plaid`,
    "redirect_uri_guestwelcomesuccess": `guest/business/:bid/guests/:gid/welcome/success`,
    "redirect_uri_guestwelcomesuccesswechat": `${window.location.origin}/guest/business/:bid/guests/:gid/welcome/success`,

    "redirect_uri_guestpurchase": `guest/business/:bid/guests/:gid/purchases/:pid`,

}
export const FacepayText = {

    activateQrUrl: Facepay.domain + "/activate/:bid",
    welcomeQrLinkUrl: `${window.location.origin}/qr/:uuid`,
    welcomeQrUrl: `${window.location.origin}/guest/business/:bid/guests/0x0/welcome?bname=:bname&bgravatar=:bgravatar`,
    welcomeRedirectQrUrl: `${window.location.origin}/guest/business/:bid/guests/0x0/redirect?bname=:bname&bgravatar=:bgravatar`,
    welcomeQrOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome",
    welcomeOnlyUrl2: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome",
    homeOnlyUrl: "/guest/business/:bid/guests/0x0/home?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar",
    welcomeOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/0x0/welcome?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar",
    welcomeUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/welcome?uuid=:uuid&bname=:bname&cell=:cell&bgravatar=:bgravatar",
    purchaseUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/purchases/:pid",
    purchaseOnlyUrl: Facepay.domain + "/guest/business/:bid/guests/:gid/purchases/:pid?isPicture=:isPicture&cell=:cell&amount=:amount&date=:date&bname=:bname&bgravatar=:bgravatar"


}


export const Google = {
    analytics: 'UA-64860131-3',
}



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
